import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { CompanyLogoIcon } from '@components/Icons';

const AuthPageWrapper = ({ children, logoColor, backgroundColor, backgroundImageClass }) => (
  <div className={`mih--100p layout-column layout-align-start-stretch bg--${backgroundColor} ${backgroundImageClass}`}>
    <header>
      <nav className="relative w--100p h--100p layout-row layout-align-start-center m-horizontal--auto p-horizontal--large p-horizontal--x-large-gt-xs">
        <Link href="/">
          <a className="inline-block hover-none">
            <CompanyLogoIcon className={`vertical-middle icon--${logoColor} block h--48 w--auto`} />
          </a>
        </Link>
      </nav>
    </header>
    <div className="mw--576 w--100p-gt-xs m-horizontal--auto-gt-xs m-top--small m-top--large-gt-xs m-horizontal--medium p-vertical--xx-large-gt-xs p-vertical--x-large p-horizontal--x-large-gt-xs p-horizontal--large layout-column layout-align-start-center relative bg--white b--rounded m-bottom--x-large">
      <div className="mw--384 w--100p m-horizontal--auto">
        { children }
      </div>
    </div>
  </div>
);

AuthPageWrapper.propTypes = {
  // Required
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // Optional
  logoColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImageClass: PropTypes.string,
};

AuthPageWrapper.defaultProps = {
  logoColor: 'pacific',
  backgroundColor: 'white',
  backgroundImageClass: '',
};

export default AuthPageWrapper;
