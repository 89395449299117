import React, { Component } from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import queryString from 'qs';
import { Map } from 'immutable';
import Button from '@components/Core/Button';
import ErrorMessage from '@components/Forms/ErrorMessage';
import Input from '@components/Forms/Input';
import Head from '@components/Core/Head';
import { FacebookIcon, GoogleIcon } from '@components/Icons';
import AuthPageWrapper from '@components/PageWrappers/AuthPageWrapper';
import { withPageWrapper } from '@components/PageWrappers/CorePageWrapper';
import Actions from '@redux/actions';
import FormValidation from '@utilities/validation/FormValidation';

const meta = {
  metaTitle: 'Log In - Kopa',
  metaUrl: `${process.env.ESPRESSO_BASE}/login`,
  metaCanonical: `${process.env.ESPRESSO_BASE}/login`,
};

class LoginPage extends Component {
  state = {
    email: '',
    password: '',
    signupToken: null,
    redirect: null,
    showValidation: false,
    errors: Map({}),
  };

  componentDidMount() {
    // Set the body bg to hue-0 on the login page
    document.body.classList.add('bg--hue-0');
    document.getElementById('__next').style.height = '100%';

    const { isLoggedIn, router, user } = this.props;
    if (isLoggedIn) {
      if (user.get('is_host')) {
        router.push('/account/dashboard');
      } else {
        router.push('/c');
      }
      return;
    }

    const query = queryString.parse(
      location.search.replace('?', '')
    );
    this.setState({
      email: query.email || '', // if auto-filled from invite
      invite: query.invite,
      signupToken: query.signup_token,
      redirect: query.redirect,
    });
  }

  componentDidUpdate() {
    // If the user becomes authenticated while on the page the redirect them
    const { isLoggedIn, isLoading, router, user } = this.props;
    if (isLoggedIn && !isLoading) {
      if (user.get('is_host')) {
        router.push('/account/dashboard');
      } else {
        router.push('/c');
      }
    }
  }

  componentWillUnmount() {
    // Reset the body bg to white
    document.body.classList.remove('bg--hue-0');
    document.getElementById('__next').style.height = 'auto';

    const { clearLoginError } = this.props;
    clearLoginError();
  }

  handleInputChange = async (key, event) => {
    const { showValidation } = this.state;
    const { value } = event.target;
    await this.setState({
      [key]: value,
    });
    if (showValidation) {
      this.validateCredentials();
    }
  }

  validateCredentials = () => {
    const { email, password } = this.state;
    const credentials = { email, password };
    const { valid, errors } = FormValidation.validate('login', credentials);
    this.setState({
      showValidation: !valid,
      errors,
    });
    return { valid, credentials };
  }

  handleSocialLogin = (provider) => {
    const { redirect } = this.state;
    const query = redirect ? queryString.stringify({ redirect }) : null;
    window.open(`${process.env.API_ENDPOINT}/v1/auth/${provider}/signin${query ? '?' + query : ''}`, '_self');
  }

  buildSignupLink () {
    let signupLink = '/signup';

    const { redirect } = this.state;
    if (redirect) {
      signupLink += `?redirect=${encodeURIComponent(redirect)}`;
    }

    return signupLink;
  }

  login = async (event) => {
    event.preventDefault();

    const { valid, credentials } = this.validateCredentials();
    if (!valid) return;

    const { loginRequest } = this.props;
    const { redirect, invite, signupToken } = this.state;

    try {
      await loginRequest({ ...credentials, invite, signup_token: signupToken }, redirect);
    } catch (error) {
      this.setState({ password: '' });
    }
  }

  render () {
    const { showValidation, errors, email, password } = this.state;
    const { isLoading, error } = this.props;

    return (
      <>
        <Head {...meta} />
        <AuthPageWrapper backgroundColor="hue-0" backgroundImageClass="squiggle-background-image" logoColor="white">

          <h1 className="m-bottom--small ta-center">Log in</h1>
          <p className="ta-center m-top--small">Nice to see you again. Welcome back!</p>

          <Button className="google-button social w--100p m-top--x-large" onClick={this.handleSocialLogin.bind(this, 'google')}>
            <span className="icon">
              <GoogleIcon className="icon--30" />
            </span>
            Continue with Google
            <span />
          </Button>

          <Button className="facebook-button social w--100p m-top--medium" onClick={this.handleSocialLogin.bind(this, 'facebook')}>
            <span className="icon">
              <FacebookIcon className="icon--30" />
            </span>
            Continue with Facebook
            <span />
          </Button>

          <div className="horizontal-ruler w--100p relative ta-center gray-1 m-top--large">or</div>

          <form className="w--100p m-top--large" onSubmit={this.login}>
            <ErrorMessage
              className="m-bottom--large"
              error={error}
              showError={!!error}
            />

            <Input
              className={errors.get('email') ? 'error' : ''}
              error={errors.get('email')}
              handleChange={this.handleInputChange}
              id="login-email"
              label="Email Address"
              name="email"
              showErrors={showValidation}
              value={email}
            />

            <div className="relative">
              <Input
                className={`m-top--large ${errors.get('password') ? 'error' : ''}`}
                error={errors.get('password')}
                handleChange={this.handleInputChange}
                id="login-password"
                label="Password"
                name="password"
                showErrors={showValidation}
                type="password"
                value={password}
              />
              <div className="absolute a--top-xs-negative a--right">
                <Link href={`/password/reset${email && email.length > 0 ? '?email=' + email : ''}`}>
                  <a className="m-left--x-small meta hover-none">Forgot password?</a>
                </Link>
              </div>
            </div>

            <Button className="primary w--100p m-top--large" isLoading={isLoading} type="submit">
              Log in
            </Button>

            <div className="b-top--xs b--gray-2 m-top--x-large p-top--large layout-row layout-align-center-center">
              <p className="m-top--none meta">Don’t have an account?</p>
              <Link href={this.buildSignupLink()}>
                <a className="m-left--x-small meta">Sign up</a>
              </Link>
            </div>

          </form>
        </AuthPageWrapper>
      </>
    );
  }
}

LoginPage.propTypes = {
  // Required
  clearLoginError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  loginRequest: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  // Optional
  error: PropTypes.string,
};

LoginPage.defaultProps = {
  error: null,
};

const mapStateToProps = (state) => ({
  error: state.get('User').get('loginError'),
  isLoading: state.get('User').get('loginLoading'),
  isLoggedIn: state.get('User').get('isLoggedIn'),
  user: state.get('User').get('object'),
});

const mapDispatchToProps = {
  loginRequest: Actions.loginRequest,
  clearLoginError: Actions.clearLoginError,
};

export default withPageWrapper(LoginPage, {
  props: {
    showNav: false,
  },
  withRouter: true,
  mapStateToProps,
  mapDispatchToProps,
});
