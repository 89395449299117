import { Map } from 'immutable';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import ValidationLayout from '@utilities/validation/ValidationLayout';

const validate = (type, data, nonRequiredKeys = []) => {
  let errors = Map({});
  let overallValid = true;

  const formItems = ValidationLayout[`${type}ValidationLayout`];
  Object.keys(formItems)
    .filter(key => !nonRequiredKeys.includes(key))
    .forEach((key) => {
      const { valid, error } = validateInput(data, key, formItems[key]);
      if (!valid) {
        errors = errors.set(key, error);
        overallValid = false;
      }
    });

  const validation = {
    valid: overallValid,
    errors,
  };

  return validation;
};

// Private
const validateInput = (data, key, validation) => {
  let valid = true;
  let error;

  const value = data[key];

  // Skip the validation when the value is optional and not entered
  if (validation.optional && !value) {
    return {
      valid,
      error,
    };
  }

  if ((value === null || value === undefined || value.length === 0 || typeof value !== validation.type && !validation.optional)) {
    // invalid type
    valid = false;
    error = validation.error ? validation.error : `${validation.label || 'This'} is required.`;
  } else if (validation.min && value < validation.min) {
    valid = false;
    error = validation.label || `Must be higher than ${validation.min}.`;
  } else if (validation.max && value > validation.max) {
    valid = false;
    error = `Must be lower than ${validation.max}.`;
  } else if (validation.minlength && value.length < validation.minlength) {
    valid = false;
    error = `Must be more than ${validation.minlength} characters long.`;
  } else if (validation.maxlength && value.length > validation.maxlength) {
    valid = false;
    error = `Must be less than ${validation.maxlength} characters long.`;
  } else if (validation.pattern && !value.match(validation.pattern)) {
    valid = false;
    error = 'Please make sure the input is valid.';
  } else if (validation.patterns && !validation.patterns.find(p => value.match(p))) {
    valid = false;
    error = 'Please make sure the input is valid.';
  } else if (validation.number && value.search(/\d/) === -1) {
    valid = false;
    error = 'Please make sure there is at least 1 number.';
  } else if (validation.uppercase && value.search(/[A-Z]+/) === -1) {
    valid = false;
    error = 'Please make sure there is at least 1 uppercase letter.';
  } else if (validation.custom === 'phone_number') {
    ({ valid, error } = phoneNumberValidation(value));
  }

  return {
    valid,
    error,
  };
};

const phoneNumberValidation = (value) => {
  let valid = true;

  const phoneNumber = parsePhoneNumberFromString(value, 'US');
  if (phoneNumber) {
    valid = phoneNumber.isValid();
  } else {
    valid = false;
  }
  return {
    valid,
    error: valid ? null : 'Please make sure the input is valid.',
  };
};

export default {
  validate,
};
